@media only screen and (max-width: 1440px) {
    .zsuite-tool img {
        width: 330px;
    }
    .login-right-box{
        width: calc(55% + 60px);
        padding: 20px 31px;
        min-height: 454px;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 1340px) {
    .login-left-box{
        padding: 0 50px 40px 0px;
    }
    .login-right-box{
        width: calc(55% + 60px);
        padding: 20px 31px;
        min-height: 504px;
        justify-content: space-around;
    }
    .z-suite-logo h4{
        font-size: 55px;
        line-height: 55px;
    }
    .z-suite-logo img{
        width:auto;
    }
    .zsuite-tool img {
        width: 275px;
    }
}
@media only screen and (max-width: 1140px) {
    .forgot-screen.get-verify{
        height:auto;
    }
}
@media only screen and (max-width: 1024px) {
    .login-items {
        width: 100%;
        display: block;
        min-height: auto;
    }
    .login-left-box{
        padding: 30px 0;
        width:100%;
        min-height: auto;
        background: #ffffff;
    }
    .login-right-box{
        width: 96%;
        position: relative;
        display: block;
        box-shadow: 4px 0px 10px 5px rgba(0, 0, 0, 0.1);
        margin:0 auto;
    }
    .login-right-left-info h3{
        font-size: 30px;
        line-height: 40px;
    }
    .zuite-list-point{
        margin-top:17px;
    }
    .zuite-list-point div{
        padding-bottom: 8px;
    }
    .zsuite-tool{
        margin-left:0;
        margin-top:20px;
    }
    .zsuite-tool img {
        width: 100%;
    }
    .left-footer-text{
        padding: 20px 10px;
    }
    .right-footer-text{
        position: relative;
        width: 100%;
        text-align: center;
        padding: 20px 0;
        bottom: 0;
        right: 0;
    }
    .z-suite-logo img {
        width: auto;
    }
    .box-center-aligned{
        width: 83%;
        flex: unset;
    }
    .left-footer-text.desktop {
        display: none;
    }
    .left-footer-text.mobile-only {
        display: block;
    }
    .right-footer-text {
        display: none;
    }
    .z-suite-logo h4{
        font-size: 60px;
        line-height: 60px;
    }

}
@media only screen and (max-width: 767px) {
    .forgot-screen{
        padding: 0 20px;
    }
}
@media only screen and (max-width: 570px) {
    .forgot-screen{
        padding: 0 20px;
    }
    .forgot-boxes{
        width:100%;
        padding: 35px 20px;
        margin:40px auto 20px auto;
    }
}