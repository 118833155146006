body{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
.login-items {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.login-left-box {
    width: 45%;
    background: #F5F5F5;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 100px 40px 0px;
    position: relative;
    flex-direction: column;
}
.box-center-aligned{
    width: 65%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-Heading img{
    width:210px;
}
.login-Heading h3{
    color: #444444;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    margin-top: 28px;
}
.login-Heading p{
    color: #828282;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
}
.login-form-fields{
    margin-top:20px;
}
.simple-input{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border-radius: 6px;
    height:55px;
    padding: 0 15px;
}
.simple-input.error{
   border: 1px solid #a94442 !important; 
}
.simple-input span{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color:#8692A6;
    padding-left: 8px;
    cursor: pointer;
}

.simple-input span:hover{
    color:#000000;
}
.simple-input .form-control{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    background: transparent;
    color:#333333;
    box-shadow: none;
    border:none;
    height:40px;
    padding: 0;
}
.simple-input-box.form-group{
    margin-bottom: 20px;
}
.button-container{
    width:100%;
    margin-top:40px;
}
.button-container span{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    padding: 10px 0;
}
.blue-button{
    background: #0079C3 !important;
    border-radius: 6px;
    height:55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color:#ffffff;
    width:100%;
}
.transparent-button{
    background: transparent !important;
    border:1px solid #8692A6 !important;
    border-radius: 6px;
    height:55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color:#000000 !important;
    width:100%;
}
.orange-button{
    background: #e6fb04 !important;
    border-radius: 6px;
    height:55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color:#000;
    width:100%;
    border:none;
}
.orange-button:hover, .orange-button:active {color:#000;}
.defalut-loading-button{
    background: #000000 !important;
    border-radius: 6px;
    height:55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color:#ffffff;
    width:100%;
}
.defalut-loading-button img{
    width:40px;
    height:40px;
}
.need-help{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color:#000000;
}
.left-footer-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.left-footer-text p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #8692A6;
}
.left-footer-text p a{
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    padding-left: 3px;
}
.login-right-box{
    min-height: 554px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: calc(55% + 130px);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 100px;
    box-shadow: 8px 0px 20px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.zsuite-tool{
    margin-left:0px;
}
.zsuite-tool img{
    width:420px;
}
.right-footer-text{
    position: absolute;
    bottom:40px;
    right:60px;
    color: #8692A6;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
}
.right-boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
}
.login-right-left-info h3{
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 80px;
    letter-spacing: 1px;
    color: #00183B;
}
.z-suite-logo {
    position: relative;
    display: flex;
}
.z-suite-logo h4{
    letter-spacing: 2px;
    color: #00183B;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 70px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.beta{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #E26131;
    text-transform: uppercase;
    top: -10px;
    right: 0;
}
.zuite-list-point{
    margin-top:30px;
}
.zuite-list-point div{
    display: flex;
    align-items: center;
    padding-bottom: 13px;
}
.zuite-list-point div span{
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #252B42;
    padding-left: 10px;
}
.left-footer-text.mobile-only {
    display: none;
}
.validation-error {
    color: red;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.forgot-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:17px;
}
.forgot-box a{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color:#000000;
    text-decoration: none !important;
}
.forgot-screen{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    height:100vh;
}
.forgot-boxes{
    width: 470px;
    padding: 30px;
    margin: 20px auto 20px auto;
    border: 1px solid #8692a6;
    box-sizing: border-box;
    border-radius: 4px;
}
.forgot-content-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.forgot-content-box span{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #595959;
}
.forgot-form{
    width:100%;
}
.forgot-form  label {
    font-size: 16px;
    line-height: 19px;
    color: #696f79;
    display: flex;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 15px;
}
.verification-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.verification-box img{
    margin-bottom:15px;
}
.verification-box span{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #595959;
    margin-bottom:20px;
}
.verification-box p{
    padding: 15px;
    background: #dff0d8;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #3c763d;
    display: flex;
    width:100%;
    margin-bottom: 0;
}
.forgot-form .blue-button{
    color:#ffffff !important;
    text-decoration: none !important;
}
.forgot-content-box img{
    margin-bottom:15px;
}
.bottom-reserve-text{
    color: #8692A6;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 20px;
}
.check-square{
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: 0 !important;
    margin-top: 3px;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    font-size:14px;
    font-weight: 300;
    margin-top:15px;
}
.validation-error{
    font-size:13px;
    color: #a94442;
    font-weight: 300;
}
.backto-login{
    display: flex;
    align-items: center;
    width:470px;
    margin:0 auto 20px auto;
    justify-content: flex-start;
}
.backto-login img{
    width:14px;
    height:14px;
    margin-right:5px;
}
.backto-login a{
    font-size:12px;
    color:#0079C3;
    font-weight: 400;
    text-decoration: none;
}
.authModal.opt-delete.cardModal.modal .modal-dialog {
    max-width: 350px;
}
.authModal.opt-delete.cardModal.modal .modal-content {
    padding: 25px 25px 20px;
    border: none;
}
.modal-content {
    border-radius: 16px!important;
}
.sucess-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.success-message-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #595959;
    width: 100%;
    padding: 15px 0 20px;
}
.close-buton.for-success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5px;
}
.close-buton.for-success a {
    background: #0079c3!important;
    border: 1px solid #0079c3!important;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80px;
    min-width: auto;
    height: 32px;
    color: #fff!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
img.hideshow-pass {
    width: 20px;
    height:20px;
}
.warning {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.warning p {
    padding: 15px;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    width:100%;
    margin-bottom: 0;
    margin-top: 16px;
}
.login-loader{display: flex;justify-content: center;align-items: center;width: 100%;height: 100vh;}